<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="Hỗ trợ trực tuyến" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="iframe_page">
      <iframe width="100%" height="100%"  frameborder="0"  id="iframe_web"   :src="this.$store.getters.getBaseInfo.kefu !==undefined ?this.$store.getters.getBaseInfo.kefu:'https://zalo.me'"  ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    back(){
      return window.history.back();
    }
  },
  created() {

  },
  mounted(){
    /**
     * iframe-宽高自适应显示
     */
    const oIframe = document.getElementById('iframe_web');
    const deviceHeight = document.documentElement.clientHeight;
    oIframe.style.height = (Number(deviceHeight)-65) + 'px'; //数字是页面布局高度差
    const tele = document.querySelector('iframe').getAttribute('src');
    if (tele.indexOf('t.me') != -1) {
      window.open(tele, '_blank');
      return window.history.back();
    }
  }
};
</script>
<script>
export default {
  name: 'CSKH',
  created() {
    if (this.$route.name === 'ServicePage') {
    window.__lc = window.__lc || {};
    window.__lc.license = 15736107;
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
    }
  },
};
</script>
<style lang='less' scoped>
@import "../../assets/css/base.css";
iframe {
  height: calc(100vh - 50px);
  width:100%;
  border:0;
}
</style>
